import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"
import PropTypes from "prop-types"
import classNames from "classnames"

const ClientList = ({ clients, borderBottom, borderTop }) => {
  return (
    <section
      aria-labelledby="clients"
      className={classNames("client-section container", {
        "border-bottom": borderBottom,
        "border-top": borderTop,
      })}
    >
      <h2 id="clients" className="descriptor">
        Clients
      </h2>
      <div className="client-list">
        {clients.map(client => {
          const caseStudy = `/work/${client.slug}`
          // const clientPage = `/work/${client.name.toLowerCase().replaceAll(' ', '-')}`

          return client.numItems > 0 ? (
            <Link
              // to={client.numItems === 1 ? caseStudy : clientPage }
              to={caseStudy}
              className="client client--link"
              key={client.node.id}
              id={client.node.id}
            >
              <Fade delay={100}>
                <ClientLogo client={client} />
              </Fade>
            </Link>
          ) : (
            <div className="client" key={client.id} id={client.id}>
              <Fade delay={1}>
                <ClientLogo client={client} />
              </Fade>
            </div>
          )
        })}
      </div>
    </section>
  )
}

const ClientLogo = ({ client }) => (
  <GatsbyImage
    className="client-logo"
    image={client.logo?.asset.gatsbyImageData}
    alt={`${client.name} logo`}
  />
)

ClientList.propTypes = {
  clients: PropTypes.array,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
}

export default ClientList
