import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AboutIntro from "../../components/aboutIntro"
import Capabilities from "../../components/capabilities"
import Features from "../../components/features"
import ClientList from "../../components/clientList"

const About = ({ location, data }) => {
  const capabilities = data.allSanityCapability.edges
  const clients = data.allSanityClient.edges
  const workItems = data.allSanityWorkItem.edges
  const values = data.sanityAboutPage.values

  clients.forEach(client => {
    const clientWithWork = workItems.filter(
      item => item.node.client.name === client.node.name
    )
    client.name = client.node.name
    client.id = client.node.id
    client.logo = client.node.logo
    client["numItems"] = clientWithWork.length
    // case studies come in sorted in descending order by updatedAt so if multiple case studies per client
    // grabbing the first one is the most recent, if only one the array is only one item
    client["slug"] =
      clientWithWork.length > 0 ? clientWithWork[0].node.slug.current : ""
  })

  return (
    <Layout scheme="dark" location={location} pageCategory="agency">
      <Seo title="MullenLowe U.S. — About" />

      <AboutIntro copy={data.sanityAboutPage._rawIntroCopy} />

      <Capabilities capabilities={capabilities} />
      <Features features={values} title="What We Believe In" />
      <ClientList clients={clients} borderTop />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityAboutPage {
      _rawIntroCopy
      heroImage {
        altText
        image {
          ...ImageWithPreview
        }
      }
      values {
        _rawDescription
        title
        _key
        image {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
    allSanityCapability(sort: { order: ASC, fields: order }) {
      edges {
        node {
          name
          id
          _rawDescription
        }
      }
    }
    allSanityClient(
      sort: { fields: name }
      filter: { excludeClientList: { ne: true } }
    ) {
      edges {
        node {
          name
          id
          excludeClientList
          logo {
            asset {
              url
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
    allSanityWorkItem(filter: {workType: {nin: "work-awards"}}, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          client {
            name
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default About
