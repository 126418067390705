import * as React from 'react'
import { useRef, createRef, useState, useEffect } from 'react'
import { PortableText } from '@portabletext/react'
import SanityImage from 'gatsby-plugin-sanity-image'
import classNames from "classnames";
import PropTypes from "prop-types"


const Features = ({ features, title }) => {
  const [listHeight, setlistHeight] = useState('auto');
  const [expandedListHeight, setExpandedListHeight] = useState();
  const [activeIndex, setActiveIndex] = useState(null);

  const listWrap = useRef();
  const featuresSection = useRef();
  const liRefs = React.useRef([]);
  liRefs.current = features.map((_, i) => liRefs.current[i] ?? createRef());

  const scrollToFeatures = () => {
    const offset = featuresSection.current.offsetTop;
    if (window.innerWidth < 744) {
      window.scrollTo({ top: offset, behavior: 'smooth', overflowx: 'auto' });
    }
    return;
  }

  useEffect(() => {
    const height = listWrap.current.offsetHeight;
    setlistHeight(height);

    // get height of each feature's descriptor text, use tallest one to get
    // height needed to animate "slide toggle"
    const descriptorHeight = liRefs.current.reduce((total, current) => {
      const currentHeight = current.current.offsetHeight;
      return currentHeight > total ? total = currentHeight + 5 : total
    }, 0);

    if (window.innerWidth < 744) {
      setExpandedListHeight(`calc(${descriptorHeight}px + 120vw)`);
    } else {
      setExpandedListHeight('auto')
    }

  }, [])

  return (
    <section
      id="features"
      ref={featuresSection}
      className="dark features"
      aria-label={title}
    >
      
      {title && (
        <h2 className="features__title">{title}</h2>
      )}

      <div
        ref={listWrap} 
        className={classNames("features__wrap", `features__wrap--${features.length}`, {
          'open': activeIndex !== null
        })}
      >
        <ul 
          className={`features__list features__list--${features.length}`} 
          style={{ height: activeIndex !== null && window.innerWidth < 744 ? expandedListHeight : listHeight }}
        >
          {features.map((feature, index) => (
            <li
              key={feature._key}
              className={`feature ${activeIndex === index ? 'active' : ''} feature--${features.length}`}
              // role="button"
              // aria-label="show related image and copy"

              // tabIndex="0"
            >
              <button
                className='feature__btn'
                aria-label={feature.title}              
                onClick={() => {
                  setActiveIndex(index)
                  scrollToFeatures();
                }}
                onKeyDown={() => {
                  setActiveIndex(index)
                  scrollToFeatures();
                }}>
                <h3 className='feature__title'>{feature.title}</h3>
                <div ref={liRefs.current[index]} className={`feature__desc feature__desc--${features.length}`}>
                  <PortableText value={feature._rawDescription} />
                </div>
                <div className='feature__img-wrap'>
                  <SanityImage {...feature.image.image} alt={feature.image.altText} />
                </div>
              </button>

            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

Features.propTypes = {
  features: PropTypes.array, 
  title: PropTypes.string
}

export default Features