import * as React from 'react'
import PropTypes from "prop-types"
import { useEffect, useState, useRef } from 'react'
import { useLocation } from '@reach/router';
import { PortableText } from '@portabletext/react'
import NextLogo from "../images/svg/MLNext_White-01.svg"

const AboutIntro = ({ copy, subhead }) => {
  const [isDesktop, setIsDesktop] = useState(true);
  const orphans = useRef(null);
  const location = useLocation();

  const NextLogoFn = () => {
    if (location.pathname === '/agency/ml-next/') {
      return (
          <NextLogo />
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    const media = window.matchMedia('(max-width: 768px)');
    const breakpointChecker = () => media.matches ? setIsDesktop(false) : setIsDesktop(true);

    media.addEventListener('change', breakpointChecker)
    breakpointChecker();

    return () => media.removeEventListener('change', breakpointChecker)

  }, [isDesktop])

  return (
    <section aria-label="intro" className="container header-spacer">
      <NextLogoFn />
      <div className="about-intro-rev">
        <div ref={orphans} className="about-intro-rev__copy-rev">
          <PortableText value={copy} />
          {subhead && (
            <p className='about-intro-rev__subhead-rev'>{subhead}</p>
          )}
        </div>
      </div>
    </section>
  )
}

export default AboutIntro

AboutIntro.propTypes = {
  copy: PropTypes.array.isRequired,
  subhead: PropTypes.string,
}